import React from "react";
import { makeStyles } from "@material-ui/core";
import { LoaderDot, LoaderBg } from './LoaderAsssets';
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  loaderHolder: {
    position: 'relative',
    width: '40px',
    height: '40px'
  },
  imgHolder: {
    position: 'absolute',
    left: 0,
    top: 0
  },
  zoomInOut: {
    animationName: `$zoomInOut`,
    animationDuration: '2s',
    animationFillMode: 'both',
    animationIterationCount: 'infinite',
  },
  "@keyframes zoomInOut": {
    "0%": {
      transform: "scale(1)",
      opacity: 1,
    },

    "50%": {
        transform: "scale(0.7)",
        opacity: 0,
    },
    "100%": {
        transform: "scale(1)",
        opacity: 1,
    },
  }
}));

const LoaderElement = () => {
  const classes = useStyles();

  return (
    <div className={classes.loaderHolder}>
      <div className={clsx(classes.imgHolder, classes.zoomInOut)}><LoaderDot/></div>
      <div className={classes.imgHolder}><LoaderBg/></div>
    </div>
  );
}

export default LoaderElement;