import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Divider, Grid, Typography } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import UpgradeAlert from './UpgradeAlert';

const useStyles = makeStyles(theme => ({
  card: {
    width:'200px',
    borderRadius: '10px',
    marginRight:'20px',
    background: props => props.pricePlanName === "Starter" ? 'linear-gradient(180deg, #29b9d6 0%, #29b9d6 100%)' : props.pricePlanName === "Growth" ? 'linear-gradient(180deg, #429bdb 0%, #429bdb 100%)' : props.pricePlanName === "Premium" ? 'linear-gradient(180deg, #1D4C92 0%, #3D6ACF 100%)' : props.pricePlanName === "Enterprise" ? 'linear-gradient(180deg, #093973 0%, #093973 100%)' : 'transparent',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
    marginBottom:'20px',
    '&:hover': {
      boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.6)'
    }
  },
  contentRoot: {
    padding:'10px',
    "&:last-child": {
      paddingBottom: '10px',
    }
  },
  titleDiv: {
    height: '32px',
    boxSizing:'border-box',
  },
  title: {
    boxSizing:'border-box',
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '14px',
    paddingTop: theme.spacing(1),
    paddingBottom:'4px'
  },
  currentPlan: {
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '21px',
    color: props => props.pricePlanName === "Free" ? '#A4AAB6' : '#fff',
  },
  priceDiv: {
    height: '60px',
    boxSizing:'border-box',
  },
  price: {
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '24px',
    align: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  priceCustom: {
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '24px',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  priceMonth: {
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '14px',
    align: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  currencysymbol: {
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '18px',
    paddingTop: theme.spacing(1),
  },
  detailtext: {
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontSize: '14px',
    paddingLeft: '10px'
  },
  detail: {
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  divider: {
    height:'4px',
    color:'#E9E9E9',
  },
  upgradebtn: {
    width: '100%',
    height: '50px',
    marginTop:'20px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '21px',
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff',
    background: 'rgba(232, 232, 232, 0.6)',
    borderRadius: '5px',
    border: 'transparent',
    cursor:'Pointer',
    "&:hover": {
      backgroundColor: "rgba(232, 232, 232, 0.8)",
    },
  },
  currentPlanBtn: {
    width: '100%',
    height: '50px',
    marginTop:'20px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '21px',
    background: 'transparent',
    borderRadius: '5px',
    border: '2px solid #263C67',
    color: props => props.pricePlanName === "Free" ? '#263C67' : '#fff'
  },
  input: {
    display: 'block',
    color:'#263C67',
    paddingLeft: '6px',
    fontFamily: 'Poppins',
    alignItems: 'center',
    margin: '0px auto 8px auto',
    width: '80%',
    border: '0px',
    borderRadius: '5px',
    "&:focus": {
      outline : 'none',
    },
  },
}))

const PlanCard = ({plan, updatePricePlan,setOpenDialog, getPlanName, ...props}) => {
  const classes = useStyles(plan);
  const [open, setOpen] = useState(false);
  const [viewCount,setViewCount] = useState(1);
  const [planPrice,setPlanPrice] = useState(1);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCustomPlanSubmit = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <>
          <Card className={classes.card}>
            <CardContent classes={{ root: classes.contentRoot }}>
              <Grid container justify='center' direction="column" alignItems="center" >
                <div className={classes.titleDiv}>
                  <Typography className={classes.title}>
                    {plan.pricePlanName}
                  </Typography>
                </div>
                <div className={classes.priceDiv}>
                  <Grid container direction="row" alignItems="flex-start" justify='center'>
                    {plan.pricePlanName !== "Enterprise" && <Typography className={classes.currencysymbol}>
                      $
                    </Typography>}
                    {plan.price !== "custom" ? <Typography className={classes.price}>
                      {plan.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<span className={classes.priceMonth}>/month</span>
                    </Typography> : <Typography className={classes.priceCustom}>
                      <input type="number" onChange={(e) => setPlanPrice(e.target.value)} className={classes.input} placeholder="price"/>
                    </Typography>}
                  </Grid>
                </div>
              </Grid>
              <Divider variant="middle" className={classes.divider} />
              <Grid container direction="column" alignItems="center" justify='center'
                className={classes.detail}>
                {plan.details.map((detail, index) => ( plan.pricePlanType !== "custom" &&
                  <Grid container direction="row" alignItems="center" justify="flex-start" key={index} style={{ marginBottom: '10px' }}>
                    <CheckCircleOutlineIcon style={{ color: plan.pricePlanName === "Free" ? '#263C67' : '#fff' }} />
                    <Typography className={classes.detailtext}>
                      {detail}
                    </Typography>
                  </Grid>
                ))}
                {plan.pricePlanType === "custom" && <div><Grid container direction="row" alignItems="center" justify="flex-start" style={{ marginBottom: '10px' }}>
                    <CheckCircleOutlineIcon style={{ color: plan.pricePlanName === "Free" ? '#263C67' : '#fff' }} />
                    <Typography className={classes.detailtext}>
                      Unlimited designs
                    </Typography>
                  </Grid>
                  <form onSubmit={handleCustomPlanSubmit}>
                    <input type="number" min="1" onChange={(e) => setViewCount(e.target.value)} className={classes.input} required placeholder="view counts"/>
                    <button disabled={false} type="submit" className={classes.upgradebtn}>Upgrade Now</button>
                  </form>
                  </div>}
                {(!props.current && plan.pricePlanType !== "custom") && <button className={classes.upgradebtn} onClick={handleClickOpen}>Upgrade Now</button>}
                {(props.current && plan.pricePlanType !== "custom") && <button className={classes.currentPlanBtn}>Your Current Plan</button>}
              </Grid>
            </CardContent>
          </Card>
      <UpgradeAlert open={open} setOpen={setOpen} plan={plan} updatePricePlan={updatePricePlan} planPrice={planPrice} viewCount={viewCount} setOpenDialog={setOpenDialog} getPlanName={getPlanName}/>
    </>
  );
};

export default PlanCard;
