import React, {useState} from 'react';
import { Input, FormControl,FormControlLabel,Checkbox} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Auth } from 'aws-amplify';
import SignLogo from '../../images/signLogo.png';
import GoogleLogo from '../../images/googleLogo.png';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  container:{
    position:'relative',
    width:'100%',
    minHeight:'100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    maxWidth:'760px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom:'100px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    maxWidth:'400px',
  },
  ogmoLogo: {
    width: 190,
    height: 47,  
  },
  logoText: {
    color: '#203256',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
    textAlign:'center',
  },
  textLink: {
    color: '#3D6ACF',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
    marginBottom:'24px',
  },
  heading:{
    color: '#293A5D',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '42px',
    fontWeight: '600',
    textAlign:'center',
  },
  formControl:{
    width:'100%',
    marginBottom:'24px',
  },
  input: {
    fontSize: 14,
    fontFamily: 'Poppins',
    borderRadius: '5px',
    alignItems: 'center',
    padding: '6px',
    width:'100%',
    height: '40px',
    border: '1px solid #C4C4C4',
    boxSizing: 'border-box',
    marginTop: '12px',
  },
  inputlabel:{
    color: '#000',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '16px',
    fontWeight: '500',
  },
  submit: {
    background: '#3D6ACF',
    borderRadius: '5px',
    height:'40px',
    color: '#FBFDFF',
    textTransform: 'capitalize',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '600',
    boxShadow:'none',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
      boxShadow:'none',
    },
  },
  googlebtn:{
    background: '#FFFFFF',
    borderRadius: '5px',
    height:'40px',
    color: 'rgba(0,0,0,0.54)',
    textTransform: 'capitalize',
    fontFamily: 'Roboto,sans-serif',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
    boxShadow:'0 2px 4px 0 rgba(0, 0, 0, 0.25)',
    display: 'inline-flex',
    justifyContent:'flex-start',
    padding:'6px 8px',
    "&:hover": {
      background: '#ECECEC',
      borderRadius: '5px',
      boxShadow:'0 2px 4px 0 rgba(0, 0, 0, 0.25)',
    },
  },
  googleText:{
    width:'100%',
    display: 'inline-flex',
    justifyContent:'center',
  },
  footer:{
    position:'absolute',
    bottom:'0',
    height:'100px',
    display:'flex',
    alignItems:'center',
    color: '#6B6B6B',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '500',
    textAlign:'center',
  },
  checkboxText: {
    color: '#000',
    fontFamily: 'Poppins,sans-serif',
    fontSize: '14px',
    lineHeight: '21px',
  },
  checkboxIcon: {
    border: '1px solid #C4C4C4',
    borderRadius: 5,
    width: 20,
    height: 20,
  },
}));

export default function SignIn() {
  const classes = useStyles();

  const [state , setState] = useState({
    email : "",
    password : ""
  })
  const [alertOpen, setAlertOpen] = useState(false);
  const [isAdminUser,setIsAdminUser] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOpen(false);
  };
  
  const handleChange = (e) => {
    const {id , value} = e.target   
    setState(prevState => ({
        ...prevState,
        [id] : value
    }))
  }

  async function handleSubmitClick(e){
    e.preventDefault();
    
    try {
      const username = state.email;
      const password = state.password
      const user = await Auth.signIn(username, password)
      const groups = user.signInUserSession.idToken.payload['cognito:groups'];
      if(groups){
        const isAdmin = groups.find(group => group === 'admin')
        if(isAdmin === 'admin')
        {
          setIsAdminUser(true);
        }
      }
    } catch (err) {
      console.log('error signing up..', err)
      if(err.message.includes('Incorrect username or password'))
      {
        setAlertOpen(true);
      }
    }

    if(!isAdminUser) {
      setAlertOpen(true);
    }

  }

  // const socialLoginHandle = () => {
  //   const user = Auth.federatedSignIn({provider: 'Google'});
  //   console.log(user)
  // }

  return (
    <Container component="main" className={classes.container}>
      <CssBaseline />
      <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} open={alertOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Incorrect username or password
        </Alert>
      </Snackbar>
      <div className={classes.paper}>
        <div style={{marginBottom:'24px'}}>
          <img className={classes.ogmoLogo} src={SignLogo} alt="logo"/>
          <div className={classes.logoText}>Augmented Commerce</div>
        </div>
        <div style={{marginBottom:'42px'}}>
          <div className={classes.heading}>Sign in to OGMO</div>
        </div>
        <div className={classes.form}>
          <form onSubmit={handleSubmitClick}>
            <FormControl className={classes.formControl}>
              <Typography className={classes.inputlabel}>username</Typography>
              <Input
                type="text"
                id="email"
                name="email"
                autoComplete="email"
                className={classes.input}
                disableUnderline
                value={state.email}
                onChange={handleChange}
                required
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <Typography className={classes.inputlabel}>Password</Typography>
              <Input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                className={classes.input}
                disableUnderline
                value={state.password}
                onChange={handleChange}
                required
              />
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.submit}
            >
              Sign In
            </Button>
          </form>
          <div className={classes.logoText} style={{color:'#000',marginTop:'15px',marginBottom:'15px'}}>OR</div>
          <Button
            fullWidth
            variant="contained"
            className={classes.googlebtn}
            onClick={() => {Auth.federatedSignIn({provider: 'Google'})}}
          >
            <img width='18px' height='18px' src={GoogleLogo} alt='google logo'/>
            <div className={classes.googleText}>
              Sign in with Google
            </div>
          </Button>
        </div>
      </div>
      <footer className={classes.footer}>
        &copy; {new Date().getFullYear()} OGMO. All Rights Reserved
      </footer>
    </Container>
  );
}