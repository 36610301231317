import React, { useReducer, useEffect } from 'react';
import './App.css'
import querystring from 'querystring';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme';
import Routes from './Routes';
import { Hub, Auth } from 'aws-amplify'
import * as api from './services/api';
import SignIn from './views/SignIn/SignIn'
import { Switch, Route } from "react-router-dom";
import {Helmet} from "react-helmet";
import PageLoader from './components/PageLoader/PageLoader';

// const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
const initialUserState = { user: null, loading: true }
// const urlParams = new URLSearchParams(window.location.search);
function App() {
  const [userState, dispatch] = useReducer(reducer, initialUserState)
  // const [redirectUrl, setRedirectUrl] = React.useState();

  // async function wooCommerceRedirect(usrData) {
  //   try {
  //     const storeUrl = localStorage.getItem('storeUrl')
  //     const authData = usrData;
  //     const params = {
  //       app_name: 'OgmoViewer',
  //       scope: 'read_write',
  //       user_id: authData.username,
  //       return_url: `${storeUrl}/wp-admin/admin.php?page=ogmo-plugin`,
  //       callback_url: `${apiEndPoint}platforms/woocommerce/callback`
  //     };
  //     const queryStringParams = querystring.stringify(params).replace(/%20/g, '+');
  //     const finalUrl = `${storeUrl}/wc-auth/v1/authorize?${queryStringParams}%3FuserId%3D${authData.username}%26host%3D${storeUrl}`
  //     return finalUrl
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // async function updateUser(usrData) {
  //   try {
  //     // await Auth.updateUserAttributes(usrData, {
  //     //   'custom:clientId2': usrData.pool.clientId,
  //     //   'custom:refreshToken2': usrData.signInUserSession.refreshToken.token
  //     // });
  //     const platformBody = {
  //       "userId": usrData.username,
  //       "consumerKey" : "",
  //       "consumerSecret" : "",
  //       "platform": "",
  //       "version": "",
  //       "host": "",
  //       "ogmoClientId": usrData.pool.clientId,
  //       "ogmoRefreshToken": usrData.signInUserSession.refreshToken.token
  //     }
  //     await api.post('platforms',platformBody)

  //     // const wooCommerceRedirectUrl = await wooCommerceRedirect(usrData);
  //     setImmediate(() => dispatch({ type: 'setUser', user: usrData }))

  //     localStorage.removeItem('storeUrl');
  //     setRedirectUrl(wooCommerceRedirectUrl)
  //     dispatch({ type: 'loaded' })
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // async function getAuthUser() {
  //   // localStorage.setItem('storeUrl', urlParams.get('storeUrl'));
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     const groups = user.signInUserSession.idToken.payload['cognito:groups'];
  //     const isAdmin = groups.find(group => group === 'admin');
  //     if(isAdmin === 'admin') {
  //       if (user) {
  //         updateUser(user)
  //       }
  //     }
  //   }
  //   catch (err) {
  //     console.log('err: ', err)
  //   }

  // }

  useEffect(() => {
    // if (urlParams.has('storeUrl')) {
    //   getAuthUser()
    // }

    Hub.listen('auth', (data) => {
      console.log(data);
      const { payload } = data
      if (payload.event === 'signIn') {
        const groups = payload.data.signInUserSession.idToken.payload['cognito:groups'];
        if(groups){
          const isAdmin = groups.find(group => group === 'admin')
          const isSuperAdmin = groups.find(group => group === 'super_admin')

            if(isAdmin === 'admin'|| isSuperAdmin === 'super_admin')
          {
            // if (localStorage.getItem('storeUrl')) {
            //   updateUser(payload.data);
            // }
            // else {
             setImmediate(() => dispatch({ type: 'setUser', user: payload.data }))
            // }
          }
        }
      }
      // this listener is needed for form sign ups since the OAuth will redirect & reload
      if (payload.event === 'signOut') {
        setTimeout(() => dispatch({ type: 'setUser', user: null }), 350)
      }
    })
    // we check for the current user unless there is a redirect to ?signedIn=true 
    if (!window.location.search.includes('?signedin=true')) {
      checkUser(dispatch)
    }
  }, [])

  if (userState.loading) {
    return (
      <div style={{ display: 'flex', height: '100%' }}>
        <PageLoader />
      </div>
    )

  }
  else if (!userState.user && !userState.loading) {

    return (<div style={styles.appContainer}>
      <BrowserRouter>
        <Helmet>
          <title>{`Admin | OGMO - Augmented Reality & 3D Visualizer`}</title>
        </Helmet>
        <Switch>
          <Route path="/">
            <SignIn />
          </Route>
        </Switch>
        </BrowserRouter>
    </div>
    )

  }
  // else if (redirectUrl && userState.user && userState.user.signInUserSession) {

  //   window.location.replace(redirectUrl)
  //   return null;
  // } 
  else if (userState.user && userState.user.signInUserSession) {

    return (

      <ThemeProvider theme={theme}>
        <BrowserRouter>
        <Helmet>
          <title>{`Admin | OGMO - Augmented Reality & 3D Visualizer`}</title>
        </Helmet>
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    )

  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'setUser':
      return { ...state, user: action.user }
    case 'setUserAndLoaded':
      return { ...state, user: action.user, loading: false }
    case 'loaded':
      return { ...state, loading: false }

    default:
      return state
  }
}

async function checkUser(dispatch) {
  try {
    const user = await Auth.currentAuthenticatedUser()
    const groups = user.signInUserSession.idToken.payload['cognito:groups'];
    const isAdmin = groups.find(group => group === 'admin');
    const isSuperAdmin = groups.find(group => group === 'super_admin')

    if(isAdmin === 'admin'|| isSuperAdmin === 'super_admin'){
      // if (localStorage.getItem('storeUrl')) {
      //   dispatch({ type: 'setUser', user })
      // } else {
        dispatch({ type: 'setUserAndLoaded', user })
      // }
    }
    else {
      dispatch({ type: 'loaded' })
    }
  } catch (err) {
    console.log('err: ', err)
    dispatch({ type: 'loaded' })
  }
}

const styles = {
  appContainer: {
    paddingTop: 48,
    height:'100%',
  },
  loading: {

  },
  button: {
    marginTop: 15,
    width: '100%',
    maxWidth: 250,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0px 16px',
    borderRadius: 2,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, .3)',
    cursor: 'pointer',
    outline: 'none',
    border: 'none',
    minHeight: 40
  },
  text: {
    color: 'white',
    fontSize: 14,
    marginLeft: 10,
    fontWeight: 'bold'
  },
  signOut: {
    backgroundColor: 'black'
  },
  footer: {
    fontWeight: '600',
    padding: '0px 25px',
    textAlign: 'right',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  anchor: {
    color: 'rgb(255, 153, 0)',
    textDecoration: 'none'
  },
  body: {
    padding: '0px 30px',
    height: '78vh'
  }
}

export default App
