import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  gray: "#C4C4C4",
  primary: {
    contrastText: '#FDFDFD',
    dark: '#355db9',
    main: "#3d6acf",
    light: colors.indigo[100]
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: "#1D4C92",
    light: colors.blue['A400']
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    dark: "#263C67",
    main: "#1D4C92",
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: '#263C67',
    secondary: colors.blueGrey[600],
    gray: '#d0d0d0',
    link: '#4362A8'
  },
  background: {
    default: '#FDFDFD',
    paper: white
  },
  disabled: { 
    main: "#C4C4C4",
    light: "#E2E2E2"
  },
  input: {
    border: "#ECECEC",
    inputBackground: "#ECECEC",
    inputColor: "#263C67",
    disabledColor: "#ECECEC"
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200]
};
