import React from 'react';
import {
  Typography,
  Card,
  Badge,
  Avatar
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    background: 'transparent'
  },
  avatarLarge: {
    width: theme.spacing(19),
    height: theme.spacing(19),
  },
  avatarShape: {
    backgroundColor: theme.palette.disabled.main,
    width: 120,
    height: 120,
    borderRadius: 5,
  },
  userCardColumn: {
    margin: theme.spacing(1),
  },
  userCardColumnInfoPanel: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  },
  userBlockedMsg:{
    width:"200px",
    paddingTop: "20px",
  }
}));

export default function UserCard({blockUser, removeUser, ...props}) {
  const classes = useStyles();
  const rectangle = <div className={classes.avatarShape} />;

  return (
    <Card className={classes.paper} elevation={0}>
      <div className={classes.userCardColumn}>
        <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }} badgeContent=" ">
          {props.userLogo === "" ? rectangle : <Avatar variant="square" alt="Remy Sharp" src={props.userLogo} className={classes.avatarLarge} />}
        </Badge>
      </div>
      <div className={clsx(classes.userCardColumn, classes.userCardColumnInfoPanel)}>
        <div>
          <div>
            <Typography style={{lineHeight:"34px"}} variant="h2" align="left" color="textPrimary">
              {props.userName}
            </Typography>
          </div>
        </div>
        <Typography align="left" variant="body1" color="textPrimary">
          {props.userEmail}
        </Typography>
        <Typography align="left" variant="body1" color="textPrimary">
          joined on {props.joinedDate}
        </Typography>
      </div>
      {props.isUserBlocked && (
        <div className={classes.userBlockedMsg}>
          <Typography style={{ color: "red" }}>User Blocked</Typography>
        </div>
      )}
    </Card>
  );
}
