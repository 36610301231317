import { Auth } from 'aws-amplify';

export const ViewerHost = process.env.REACT_APP_VIEWER_HOST;
const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;

export const get = (url) => {
  return client(url);
};

export const post = (url, body) => {
  return client(url, { data: body });
};

export const remove = (url,body={}) => {
  return client(url, { method: 'DELETE',data: body });
};

export const put = (url, body) => {
  return client(url, { method: 'PUT', data: body });
};

const client = (
  endpoint,
  { data, headers: customHeaders, ...customConfig } = {}
) => {
  const config = {
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      'Content-Type':'application/json',
      ...customHeaders,
    },
    ...customConfig,
  };
  const url = apiEndPoint.concat(endpoint);
  
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const session = await Auth.currentSession();
      if (session) {
        const idToken = session.getIdToken().getJwtToken();
        config.headers.Authorization = 'Bearer '.concat(idToken);
      }
    } catch (error) {
      Auth.signOut();
      return reject(error);
    }

    fetch(url, config).then(async (response) => {
      if (response.status === 401 || response.status === 403) {
        Auth.signOut();
        return reject('Please re-authenticate.');
      }
      const data = await response.json();
      if (response.ok) {
        return resolve(data);
      } else {
        return reject(data);
      }
    });
  });
};

const DEMO_SUBSCRIBERS_API_RESPONSE = {
  TOTAL_SUBSCRIBERS: 100,
  SUBSCRIBER_COUNT: 4,
  SUBSCRIBERS: [
    {
      id: 1,
      name: "User A",
      currentPlan: "Free",
      joinedDate: "xx/xx/xxxx",
      noOfViews: 6784,
      noOfDesigns: 30
    },
    {
      id: 2,
      name: "User B",
      currentPlan: "Free",
      joinedDate: "xx/xx/xxxx",
      noOfViews: 3422,
      noOfDesigns: 30
    },
    {
      id: 3,
      name: "User C",
      currentPlan: "Premium 1",
      joinedDate: "xx/xx/xxxx",
      noOfViews: 6784,
      noOfDesigns: 200
    },
    {
      id: 4,
      name: "User D",
      currentPlan: "Premium 2",
      joinedDate: "xx/xx/xxxx",
      noOfViews: 6784,
      noOfDesigns: 400
    },
  ]
}
export const demoSubscribers = async() => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(DEMO_SUBSCRIBERS_API_RESPONSE);
    }, 1000);
  });
}

const DEMO_SUBSCRIBER_API_RESPONSE = {
  userName: "User A",
  email: 'xxxxx@xxx.xxx',
  joinDate: 'xx/xx/xxxx',
  subscription: 'Free',
  hostedSite: 'www.samdee.com',
  designsUsed: 37,
  views: 1000,
  payment: {
    cardType: 'Visa',
    number: 'xxxx xxxx xxxx 1111',
    payments: [
      {
        date: 'xx/xx/xxxx',
        amount: 600,
        status: 'paid',
        cardNumber: 'xxxx xxxx xxxx 1112',
      }
      
    ]
  }
}

export const demoSubscriber = async(id) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(DEMO_SUBSCRIBER_API_RESPONSE);
    }, 1000);
  });
}


export const helpContent = async(id) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(DEMO_SUBSCRIBER_API_RESPONSE);
    }, 1000);
  });
}