import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import SubscribersTableHead from './SubscribersTableHead';
import SubscribersTableRow from './SubscribersTableRow';
import TablePaginationActions from './TablePaginationActions'
import * as api from '../../services/api';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  Box,
  CardActions,
  TablePagination,
  Typography,
  Select
} from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import DeleteConfirmDialog from './DeleteConfirmDialog';

import LoaderElement from '../PageLoader/LoaderElement'; 

const messages = {
  DELETE_SUCCESS: "Design Deleted!",
  LINK_COPIED: "Link Copied to Clipboard"
}

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  loader: {
    color: '#3183ae',
  },
  content: {
    padding: 0
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tablePagination: {
    fontSize: 12
  },
  snack: {
    background: '#FBFBFB',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.25)',
    borderRadius: '5px',
    justifyContent: 'center'
  },
  root: {
    boxShadow: '0px 4px 8px rgba(73, 73, 73, 0.10)',
  }
}));

const SubscribersTable = ({
  subscribers,
  isLoading,
  handleRowsPerPageChange,
  handlePageChange,
  rowsPerPage,
  count,
  page,
  pageToken
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const openSnackBar = () => {
    setSnackbarMessage(messages.LINK_COPIED);
    setOpen(true)
  }

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={open}
            onClose={handleClose}
            ContentProps={{
              "aria-describedby": "message-id",
              className: classes.snack
            }}
            message={
              <div id="message-id" style={{ display: 'flex', alignItems: 'center' }}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '28px', height: '28px', color: '#14af7e' }} viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
                <Typography style={{ marginLeft: '10px' }} variant="subtitle1">{snackbarMessage}</Typography>
              </div>
            }
            autoHideDuration={3000}
          />
          <Box>
          {isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  m={4}
                >
                  <LoaderElement/>
                </Box>
              ) : (
                <Table>
              <SubscribersTableHead />
              <TableBody>
                  {subscribers.map((subscriber, index) => {
                    return (
                      <SubscribersTableRow
                        subscriber={subscriber}
                        key={subscriber.id}
                        openSnackBar={openSnackBar}
                      />
                    );
                  })}
                </TableBody>
            </Table>
              )
                }
          </Box>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <Select
          onChange = {handleRowsPerPageChange}
          defaultValue= {10}>
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
        </Select>
        <TablePaginationActions
          page={page}
          onPageChange={(event, page) => handlePageChange(event, page)}
          pageToken={pageToken}
        />
      </CardActions>
    </Card>
  );
};

export default SubscribersTable;
