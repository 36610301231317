import React from 'react';
import {
  Typography,
  Card,
  Box,
  IconButton
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/styles';
import StyledButton from '../Shared/StyledButton';
import BillingHistoryTable from './BillingHistoryTable';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    minHeight: 120,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex',
    borderRadius: 10,
  },
  creditdetailsCardParent: {
    minHeight: 100,
  },
  creditdetailsCard: {
    minHeight: 40,
    padding: theme.spacing(1),
  },
  cardInfoLink: {
    color: theme.palette.text.link
  }
}));

export default function SubscriptionTabPanelBilling({ blockUser, removeUser, ...props }) {
  const classes = useStyles();
  return (
    <>
      <Card className={clsx(classes.card, classes.creditdetailsCardParent)}>
        <Box display="flex" flexDirection="column" justifyContent="space-between" style={{width: '100%'}}>
          <Typography align="left" variant="h5" color="textPrimary">Payment Card</Typography>
          <Card className={clsx(classes.card, classes.creditdetailsCard)} elevation={1}>
            <Box display="flex" flexDirection="row" justifyContent="space-between" style={{width: '100%'}}>
              <Typography align="left" variant="body2" color="textPrimary">Visa</Typography>
              <Typography align="left" variant="body2" color="textPrimary">**** **** **** 1111</Typography>
              <IconButton aria-label="delete" style={{padding: 0}}>
                <DeleteIcon />
              </IconButton>
            </Box>
           
          </Card>
        </Box>
        
      </Card>
      <Box mt={2}>
        <Card className={classes.card}>
          <Box display="flex" flexDirection="row" flexGrow={1}>
            <BillingHistoryTable/>
          </Box>
        </Card>
      </Box>
    </>
  );
}
