import React from 'react';
import { Input } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const InputField = withStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "376px",
    height: '40px',
    borderWidth: '2px',
    borderStyle: 'solid',
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '21px',
    borderColor: theme.palette.input.border,
    color: theme.palette.input.inputColor,
    boxSizing: 'border-box',
    borderRadius: '5px',
  },
  disabled: {
    background: theme.palette.input.disabledColor,
  }
}))((props) => <Input disableUnderline {...props} />);

export default InputField;