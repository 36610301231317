import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { TextField } from '@material-ui/core';
import SuccessDialog from './SuccessDialog';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    paper: { 
        minWidth: "400px", 
        minHeight: "160px", 
        padding: "25px 60px" 
    },
    actionButton: {
        padding: "12px 35px", 
        fontSize: "14px", 
        textTransform: "capitalize"
    },
    dialogActions: {
        display: "flex",
        marginTop: "12px",
        paddingBottom: "30px",
        justifyContent: "center",
        gap: "20px"
    }
}));

export default function PasswordConfirmationDialog({ onConfirm, onClose, ...props }) {
    const classes = useStyles();

    const handleClose = () => {
        props.setSecretCode('')
        onClose()
    };

    const handleContinue = () => {
        onConfirm()
    };

    return (
        <>
            <Dialog
                classes={{ paper: classes.paper}}
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                    <DialogContentText style={{ textAlign: 'center' }} id="alert-dialog-description">
                        An email with an OTP was just sent to {props.authuser && props.authuser.attributes.email}, paste it here for the confirmation.
                    </DialogContentText>
                    <TextField  
                        style={{display:"block", textAlign:"center"}}
                        size="small"
                        error={props.error}
                        required
                        id="filled-password-input"
                        type="password"
                        autoComplete="current-password"
                        variant="outlined"
                        value={props.secretCode}
                        onChange={(event) => { props.setSecretCode(event.target.value) }}
                    />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button className={classes.actionButton} variant="contained" color="primary" onClick={handleClose}>Cancel</Button>
                    <Button className={classes.actionButton} onClick={handleContinue} color="primary" variant="outlined" autoFocus>Continue</Button>
                </DialogActions>
            </Dialog>
            {props.successDialog &&
                <SuccessDialog
                    open={props.openSuccessDialog}
                    onClose={() => props.setOpenSuccessDialog(false)}
                    title={props.successTitle}
                    content={props.successContent}
                />
            }
        </>
    );
}
