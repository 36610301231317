/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_qy1uPlepL",
    "aws_user_pools_web_client_id": "4g53t18j4kjjiqmcj1pk92cjte",
    "oauth": {
        "domain": "ogmov3-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "https://admin.app3.ogmo.xyz/",
        "redirectSignOut": "https://admin.app3.ogmo.xyz/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
