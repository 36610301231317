import React from 'react';
import {
  Tabs,
  Tab,
  Box
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import SubscriptionTabPanelGeneral from './SubscriptionTabPanelGeneral';
import SubscriptionTabPanelBilling from './SubscriptionTabPanelBilling'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.card,
  },
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>{children}</Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AntTabs = withStyles((theme) => ({
  root: {
    width: 360,
    marginLeft: theme.spacing(4),
  },
  indicator: {
    backgroundColor: theme.palette.text.primary,
    height: 4
  },
}))(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSizeMedium.fontSize,
    lineHeight: theme.typography.fontSizeMedium.lineHeight,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: theme.palette.primary.dark,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color:  theme.palette.text.primary,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function SubscriptionTabPanel({ blockUser, removeUser, ...props }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div position="static">
        <AntTabs  value={value} onChange={handleChange} aria-label="simple tabs example">
          <AntTab label="General Information" {...a11yProps(0)} />
          <AntTab label="Billing History" {...a11yProps(1)} disabled/>
        </AntTabs >
      </div>
      <TabPanel value={value} index={0}>
        <SubscriptionTabPanelGeneral
          pricePlanType = {props.billing.pricePlanType}
          pricePlanDef = {props.billing.pricePlanDefId}
          designViewCount = {props.designs.designViewCount}
          designCount = {props.designs.designCount}
          maxViewCount = {props.planDef.designViewCount}
          maxCount = {props.planDef.designCount}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SubscriptionTabPanelBilling
          pricePlanType = {props.billing.pricePlanType}
        />
      </TabPanel>
    </div>
  );
}
