import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Link as RouterLink } from "react-router-dom";
import { Box, Grid } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import CustomBreadCrumb from '../../components/Shared/CustomBreadCrumb';

import InputField  from '../../components/Shared/InputField';
import InputFieldLabel from '../../components/Shared/InputFieldLabel';
import StyledButton from '../../components/Shared/StyledButton';
import BodyText from './../../components/Shared/BodyText';

import { checkUsernameIsLimited } from './../../util/';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  container: {
    paddingLeft:'20px',
    paddingRight:'20px',
  },
  body: {
    padding: "10px 10px 10px 0px",
    spacing:"0px",
    align: "center",
    justify: "center",
  },
  button: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(2),
  },
}))

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Password = () => {
  const classes = useStyles();

  const [currentPass, setCurrentPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [isPasswordError, setIsPasswordError] = useState(false); 
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [open, setOpen] = useState(true);
  const [error, setError] = useState('Error on changing password');
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [isGoogleUser, setIsGoogleUser] = useState(false);

  useEffect(() => {
    checkUser();
  }, []);

  async function checkUser() {
    try {
      const auth = await Auth.currentAuthenticatedUser();  
      if (auth) {
        if(!checkUsernameIsLimited(auth.username)){
          setIsUpdateDisabled(false);
        } else {
          setIsUpdateDisabled(true);
        }
        if (auth.username.split('_')[0] === 'google') {
          setIsGoogleUser(true);
        } else {
          setIsGoogleUser(false);
        }
      }
    }
    catch (err) {
      console.log('Auth user error: ', err);
    }
  }
  
  async function handleSubmit() {
    setIsPasswordError(false);
    if (newPass===confirmPass) {
      Auth.currentAuthenticatedUser()
      .then(user => {
          return Auth.changePassword(user, currentPass, newPass);
      })
      .then(data => {
        setIsPasswordSet(true);
        window.location.reload();
      })
      .catch(err => {
        setError(err.message);
        setIsPasswordError(true);
        setTimeout(() => {
          setIsPasswordError(false);
        }, 4000);
      });
    } else {
      setError('Confirm password not matched with New Password');
      setIsPasswordError(true);
      setTimeout(() => {
        setIsPasswordError(false);
      }, 4000);
    }
  }

  const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
		setOpen(false);
	};

  return (
    <Box className={classes.container}>
      <CustomBreadCrumb breadcrumbs={[
        { text: "Settings", isCurrent: false },
        { text: "Password", isCurrent: true },
      ]} />
      <Box>
        <Grid
          container
          className={classes.body}
          spacing={3}
        >
          <Grid item xs={12}>
            <BodyText>Change your password below. Once your password changed, you will be
              <br />
              directed to Sign in Screen. </BodyText>
          </Grid>
          <Grid item xs={12}>
            <InputFieldLabel htmlFor="currentPassword">Current Password</InputFieldLabel>
            <InputField
              id="currentPassword"
              type="password"
              autoComplete="current-password"
              disableUnderline
              disabled={isGoogleUser || isUpdateDisabled}
              onChange={e => setCurrentPass(e.target.value)}
            />
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} md={4}>
              <InputFieldLabel htmlFor="newPassword">New Password</InputFieldLabel>
              <InputField
                id="newPassword"
                type="password"
                disableUnderline
                autoComplete="new-password"
                disabled={isGoogleUser || isUpdateDisabled}
                onChange={e => { setNewPass(e.target.value) }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputFieldLabel htmlFor="confirmPassword">Confirm Password</InputFieldLabel>
              <InputField
                id="confirmPassword"
                type="password"
                disableUnderline
                autoComplete="confirm-password"
                disabled={isGoogleUser || isUpdateDisabled}
                onChange={e => { setConfirmPass(e.target.value) }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <StyledButton
              disabled={isGoogleUser || isUpdateDisabled}
              onClick={handleSubmit}
              className={classes.button}
            >
              Update Password
            </StyledButton>
          </Grid>
        </Grid>
        {isPasswordError &&
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {error}
            </Alert>
          </Snackbar>
        }
        {isPasswordSet &&
          <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              Password Changed
            </Alert>
          </Snackbar>
        }
      </Box>
    </Box>
  );
};

export default Password;
