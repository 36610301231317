import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Grid } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import * as api from '../../services/api';
import CustomBreadCrumb from '../../components/Shared/CustomBreadCrumb';
import PageLoader from '../../components/PageLoader/PageLoader';

import BodyText from './../../components/Shared/BodyText';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  container: {
    paddingLeft:'20px',
    paddingRight:'20px',
  },
  body: {
    padding: "10px 10px 10px 0px",
    spacing:"0px",
    align: "center",
    justify: "center",
  },
}))


const Help = () => {
  const classes = useStyles();
  const [isInProgress, setIsInProgress] = useState(false);

  useEffect(() => {
    loadHelp();
  }, [])

  async function loadHelp() {
    try {
      const auth = await Auth.currentAuthenticatedUser();
      if (auth) {
        getHelp(auth.username);
      }
    }
    catch (err) {
      console.log('Auth user error: ', err)
    }
  }

  async function getHelp(userId) {
    setIsInProgress(true);
    await api.helpContent()
      .then(data => {
        setIsInProgress(false);
      })
      .catch(error => {
        console.log('getHelp API Error: ', error.message);
        setIsInProgress(false);
      });
  }

  if (isInProgress) {
    return(
      <PageLoader/>
    );
  } else {
    return (
      <Box className={classes.container}>
        <CustomBreadCrumb breadcrumbs={[
        { text: "Settings", isCurrent: false },
        { text: "Help", isCurrent: true },
      ]} />
        <Box>
          <Grid
            container
            className={classes.body}
            spacing={3}
          >
            <Grid item xs={12}>
              <BodyText>Change your password below. Once your password changed, you will be
                <br />
                directed to Sign in Screen. </BodyText>
            </Grid>
          </Grid>
        </Box>
      </Box>
    )
  };
}

export default Help;
