import React from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import RouteWrapper from "./components/RouteWrapper/RouteWrapper";
import Subscribers from "./views/Subscribers/Subscribers";
import { Main as MainLayout } from "./layouts/Main";
import Dashboard from "./views/Dashboard/Dashboard";
import Settings from "./views/Settings/Settings";
import UpdateSubscriber from "./views/Subscribers/UpdateSubscriber";
import Help from "./views/Settings/Help";
import Password from "./views/Settings/Password";
import UserProfile from "./views/Settings/UserProfile";

const allRoutes = [
    // {
    //     component: Dashboard,
    //     exact: true,
    //     layout: true,
    //     path: "/dashboard",
    // },
    {
        component: Subscribers,
        exact: true,
        layout: true,
        path: "/subscribers",
    },
    {
        component: Settings,
        exact: true,
        layout: true,
        path: "/settings",
    },
    {
        component: UserProfile,
        exact: true,
        layout: true,
        path: "/settings/userprofile",
    },
    {
        component: Password,
        exact: true,
        layout: true,
        path: "/settings/password",
    },
    {
        component: UpdateSubscriber,
        exact: true,
        layout: true,
        path: "/updateSubscriber/:subscriberId",
    },
];

const Routes = () => {
    var routesWithMainLayout = [];
    var routesWithoutMainLayout = [];

    allRoutes.forEach(({ component, exact, layout, path }, index) => {
        if (layout) {
            routesWithMainLayout.push((
                <RouteWrapper
                    component={component}
                    exact={exact}
                    path={path}
                    key={index}
                />
            ));
        } else {
            routesWithoutMainLayout.push((
                <Route component={component} exact={exact} layout={null} path={path} key={index} />
            ));
        }
    });

    return (
        <Switch>
            <Route path="/editor" component={null}>
                {routesWithoutMainLayout}
            </Route>
            <Route path="/" component={null}>
                <MainLayout>
                    <Switch>
                        {routesWithMainLayout}
                        <Redirect from="/" to="/subscribers" key="redirect" />
                    </Switch>
                </MainLayout>
            </Route>

        </Switch>
    );
};


export default Routes;