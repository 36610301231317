import React from 'react';
import { Button } from '@material-ui/core';
import {  withStyles } from '@material-ui/styles';

const StyledButton = withStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '5px',
    fontFamily: 'Poppins',
    textTransform: 'capitalize',
    padding:'8px 16px',
    fontSize: "14px",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  containedSecondary: {
    backgroundColor: theme.palette.secondary.main,
  }
}))((props) => <Button variant="contained" {...props} />);

export default StyledButton 