import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from "@material-ui/core";
import { useHistory } from 'react-router-dom';

export default function SuccessDialog({ onConfirm, onClose, ...props }) {
    const handleClose = () => {
        onClose()
    };
    const history = useHistory();

    return (
        <>
            <Dialog
                open={props.open}
                onBackdropClick={()=>{history.push("/subscribers")}}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title"><Typography align="center" variant="h5">{props.title}</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText align="center" id="alert-dialog-description">{props.content}</DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
}
