import { DISABLED_USER_UPDATE_USERNAME_PREFIX } from './constants';

export const checkUsernameIsLimited = (user) => {
  let isDisabledPrefixExists = false;

  for (let userNamePref of DISABLED_USER_UPDATE_USERNAME_PREFIX) {
    if (user.indexOf(userNamePref) !== -1) {
      isDisabledPrefixExists = true;
      break;
    }
  }

  return isDisabledPrefixExists;
}


export const formatDate = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [day, month, year].join('/');
}