import React, { useState, useEffect } from 'react';
import {
  Typography,
  Card,
  Box,
  Modal,
  Button ,
  Grid,
  Dialog
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import StyledButton from '../Shared/StyledButton';
import CloseIcon from '@material-ui/icons/Close'
import PageLoader from '../PageLoader/PageLoader';

import CustomLinearProgress from './../Shared/CustomLinearProgress'
import { useParams } from "react-router-dom";
import * as api from '../../services/api';
import PlanCard from './PlanCard';
import { SuperAdminErrorMessage } from '../Shared/SuperAdminErrorMessage';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4),
    minHeight: 120,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    display: 'flex'
  },
  cardInfoLink: {
    color: theme.palette.text.link
  },
  paperStyle: {
    padding: '20px',

  },
  changePlanModal: {
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTitle: {  
    fontSize: "20px",
    textAlign:"center",
    fontWeight: 500,
    color: theme.palette.info.dark,
    marginBottom: "20px"
  },
  closeBtn: {
    position: 'absolute',
    top: '15px',
    right: '20px',
    fontSize: '22px',
    fontWeight: 'bold',
    "&:hover": {
      cursor: 'pointer',
    }
  },
  loader: {
    position: 'fixed',
    top: "50%",
    left: "50%",
    transform: `translate(50%, -50%)`,
  }
}));

export default function SubscriptionTabPanelGeneral({ ...props }) {
  const classes = useStyles();
  const params = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [isPricePlanLoading, setIsPricePlanLoading] = useState(false);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [currentPlanName, setCurrentPlanName] = useState("");
  const [maxCount, setMaxCount] = useState("");
  const [maxViewCount,setMaxViewCount] = useState("");
  const [allPlans, setAllPlans] = useState([]);
  const [open, setOpen] = useState(false);
  const [alertTitle, setAlertTitle] = useState();


  useEffect (() => {
    getPlanName(props.pricePlanDef);
  },[])

  const updatePricePlan = (selectedPlanId) => {
    api.put(`billing/pricePlans/${params.subscriberId}`, {
      pricePlanDefId: selectedPlanId
    })
      .then(() => {
        getPlanName(selectedPlanId);
        setOpenDialog(false);
      })
      .catch(err => {
        if (err.message == "you are not allowed access this resource") {
          setOpen(true)
          setAlertTitle("This action can only be performed by Super Admin")
          setOpenDialog(false)
        }
        console.log(err);
      });
  }

  async function getPlanName(planId){
    planId && api.get(`billing/pricePlanDefs/${planId}`)
        .then(data => {
          setCurrentPlan(data.data);
          setCurrentPlanName(data.data.pricePlanName);
          setMaxCount(data.data.designCount);
          if(data.data.pricePlanType == "custom"){
            getCustomPricePlan();
          } else {
            setMaxViewCount(data.data.designViewCount);
          }
        })
  }

  const getCustomPricePlan = () => {
    api.get(`billing/pricePlans?userId=${params.subscriberId}`)
      .then(data => {
        setMaxViewCount(data.data.designViewCount);
      })
      .catch(error => {
        console.log('pricePlan Error: ', error);
      });
  }

  function getAllPricePlanDefs() {
    setIsPricePlanLoading(true);
    api.get('billing/pricePlanDefs')
      .then(data => {
        setIsPricePlanLoading(false);
        let planDef = data.data;
        let tempPlanArray = [];

        planDef.map(plan => {
          const tempPlanDetail = { ...plan,  details: [plan.designCount === "unlimited"? 'Unlimited designs': `${plan.designCount} designs`, plan.designViewCount === "configurable"? 'Configurable':`${plan.designViewCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} views`] };
          tempPlanArray.push(tempPlanDetail);
        });

        tempPlanArray.sort((a, b) => {
          return a.displayOrder - b.displayOrder;
        });
        
        setAllPlans(tempPlanArray)
        setOpenDialog(true);
      })
      .catch(error => {
        console.log('pricePlanDefs Error: ', error);
      });
  }

  const CustomModal = () => {
    return (
      <Dialog
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={openDialog}
        onClose={handleClose}
        classes={{
          paper: classes.paperStyle,
        }}
        maxWidth={false}
      >
        <div style={{maxWidth: 700}}>
          <div className={classes.cardTitle}>Choose a Plan</div>
          <div className={classes.closeBtn}><CloseIcon onClick={handleClose}/></div>
          <div className={classes.changePlanModal}>
          {0 < allPlans.length !== null && (
            <Grid container spacing={2} >
              {allPlans.map(plan => {
                return (<Grid item  >
                  <PlanCard plan={plan} current={plan.pricePlanDefId === currentPlan.pricePlanDefId} updatePricePlan={updatePricePlan} setOpenDialog={setOpenDialog} getPlanName={getPlanName}/>
                </Grid>)
                })
              }
            </Grid>
          )}
          </div>
        </div>
      </Dialog>
    );
  };

  const handleOpen = () => {
    getAllPricePlanDefs();
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <SuperAdminErrorMessage  alertTitle={alertTitle} open={open} setOpen={setOpen}/>
      <Card className={classes.card}>
        <Box display="flex" flexDirection="row" flexGrow={1}>
          <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1}>
            <Box>
              <Typography align="left" variant="body2" color="textPrimary">Monthly Subscription</Typography>
              <Typography variant="h4" align="left" color="textPrimary">{currentPlanName}</Typography>
            </Box>
            <Box>
              <Typography align="left" variant="body2" color="textPrimary">Hosted Site</Typography>
              <Typography align="left" variant="body2" className={classes.cardInfoLink} color="textPrimary">www.wwwwwww.www</Typography>
            </Box>
          </Box>
          <Box>
            <StyledButton onClick={handleOpen}>Change</StyledButton>
          </Box>
        </Box>
      </Card>
      <Box mt={2} display="flex" flexDirection="row" justifyContent="space-between">
        <Box mr={1} flexGrow={1} flexBasis={0}>
          <Card className={classes.card} style={{width:"235px"}}>
            <Box display="flex" flexDirection="row" flexGrow={1}>
              <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1}>
                <Box>
                  <Typography align="left" variant="body2" color="textPrimary">Designs used for the </Typography>
                  <Typography variant="h3" align="left" color="textPrimary">{props.designCount}</Typography>
                </Box>
                <Box>
                  <Typography align="right" variant="body2" color="textPrimary">Out of {maxCount}</Typography>
                  {maxCount !== "unlimited" ? <CustomLinearProgress value={props.designCount/maxCount*100}/> : ""}
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
        <Box ml={1} flexGrow={1} flexBasis={0}>
          <Card className={classes.card} style={{width:"235px"}}>
            <Box display="flex" flexDirection="row" flexGrow={1}>
              <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1}>
                <Box>
                  <Typography align="left" variant="body2" color="textPrimary">Total views made this month</Typography>
                  <Typography variant="h3" align="left" color="textPrimary">{props.designViewCount}</Typography>
                </Box>
                <Box>
                  <Typography align="right" variant="body2" color="textPrimary">Out of {Number(maxViewCount)/1000}K</Typography>
                  <CustomLinearProgress value={props.designViewCount/(Number(maxViewCount)/1000)*100}/>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
      <CustomModal />
      {isPricePlanLoading &&
      <div className={classes.loader}>
       <PageLoader/>
      </div>}
    </>
  );
}
