import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';
import { useParams } from "react-router-dom";
import * as api from '../../services/api';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  cancelbutton: {
    border: '2px solid #3D6ACF',
    boxSizing: 'border-box',
    background: 'transparent',
    borderRadius: '5px',
    color: '#3D6ACF',
    fontFamily: 'Poppins,sans-serif',
    lineHeight: '21px',
    fontSize: '14px',
    padding:'8px 16px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    cursor:'pointer',
    marginRight: '30px',
    "&:hover": {
      border: '2px solid rgba(29, 76, 146,0.8)',
    },
  },
  savebutton: {
    backgroundColor: '#3D6ACF',
    border: '2px solid #3D6ACF',
    boxSizing: 'border-box',
    borderRadius: '5px',
    color: 'white',
    fontFamily: 'Poppins,sans-serif',
    lineHeight: '21px',
    fontSize: '14px',
    fontWeight: 600,
    padding:'8px 16px',
    cursor:'pointer',
    "&:hover": {
      backgroundColor: "rgba(29, 76, 146,0.8)",
    },
  },
  dialogStyle: {
    position:'absolute',
    top:'10px',
    padding:'40px 60px'
  },
  titleStyle: {
    fontFamily: 'Poppins,sans-serif',
    lineHeight: '21px',
    fontSize: '14px',
    fontWeight: 500,
    color:'#263C67',
    marginBottom:'20px',
  },
}));

export default function UpgradeAlert({ open, setOpen, plan, updatePricePlan, planPrice, viewCount, setOpenDialog, getPlanName }) {
    const classes = useStyles();
    const params = useParams();

    const handleClose = () => {
      setOpen(false);
    };

    const updateCustomPricePlan = (selectedPlanId) => {
        api.put(`billing/pricePlans/${params.subscriberId}`, {
          pricePlanDefId: selectedPlanId,
          price: planPrice,
          designViewCount : viewCount,
        })
        .then(() => {
            setOpenDialog(false);
            getPlanName(selectedPlanId);
        })
        .catch(err => {
          console.log(err);
        });
      }
  
    return (
      <div>
        <Dialog
          open={open}
          classes={{
            paper: classes.dialogStyle
          }}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={classes.dialogStyle}
        >
          <div style={{display:'flex',flexDirection:'column',alignItems:'center'}}>
              <div className={classes.titleStyle}>
                Are you sure you want to upgrade to this plan?
              </div>
            <div>
              <button className={classes.cancelbutton} onClick={handleClose} color="primary">
                Cancel
              </button>
              {plan.pricePlanType === "custom" ? <button className={classes.savebutton} onClick={() => updateCustomPricePlan(plan.pricePlanDefId)} color="primary" autoFocus data-test="delete_confirmation_btn">
                Upgrade
              </button> : <button className={classes.savebutton} onClick={() => updatePricePlan(plan.pricePlanDefId)} color="primary" autoFocus data-test="delete_confirmation_btn">
                Upgrade
              </button> }
            </div>
          </div>
        </Dialog>
      </div>
    );
  }