import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { Sidebar, Topbar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 5,
      height: 'calc(100% - 5px)',
    }
  },
  shiftContent: {
    paddingLeft: 240,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 240,
    },
  },
  content: {
    flexGrow: 1,
    margin: '96px 20px 24px 0px',
    alignContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  sidebar: {
    alignItems: 'stretch'
  }
}));

const Main = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
        [classes.shiftContent]: isDesktop
      })}
    >

        <Sidebar
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          variant={isDesktop ? 'persistent' : 'temporary'}
          
        />
        <Topbar onSidebarOpen={handleSidebarOpen}/>
        <main className={classes.content}>
          {children}
        </main>

    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node
};

export default Main;
