import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/styles';

const InputFieldLabel = withStyles((theme) => ({
  root: {
    color: theme.palette.input.inputColor,
    fontSize: '16px',
    fontWeight: 500,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(2),
    color: theme.palette.info.dark,
  },
}))((props) => <InputLabel {...props} />);

export default InputFieldLabel