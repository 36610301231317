import React from 'react';
import { Typography } from '@material-ui/core';
import {  withStyles } from '@material-ui/styles';

const BodyText = withStyles((theme) => ({
  root: {
    display: "flex",
    padding: "10px 10px 10px 0px",
    fontFamily: "Poppins,sans-serif",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "500",
    marginTop: "7px",
    textAlign:'left',
    display: "flex",
    padding: "10px 10px 10px 0px",
    justifyContent:'space-between',
    color: theme.palette.info.dark,
  },
}))((props) => <Typography {...props} />);

export default BodyText;