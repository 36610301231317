import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import bell from '../../../../images/notification.png';
import Profile from '../Topbar/Profile';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  flexGrow: {
    flexGrow: 1
  },
  flexGrow2: {
    flexGrow: 0.03
  },
  bell: {
    width: '24px',
    height: '32px',
    alignItems: 'center',
    color: '#263C67',
  },
  root: {
    backgroundColor:theme.palette.background.default,
    boxShadow: '0px 4px 4px rgba(91, 91, 91, 0.15)',
  },
  toolbarBg: {
    backgroundColor:theme.palette.background.default
  }
}));

const Topbar = ({ className, onSidebarOpen, ...rest }) => {
  const classes = useStyles();
  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      elevation={0}
    >
      <Toolbar>
        <div className={clsx(classes.toolbarBg, classes.flexGrow)} />
        {/* <NotificationsNoneOutlinedIcon className={classes.bell}/> */}
        <div className={classes.flexGrow2} />
        <Profile />
        <Hidden lgUp>
          <IconButton
            color="default"
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
