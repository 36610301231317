import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from "@material-ui/core";
import PasswordConfirmationDialog from './PasswordConfirmationDialog';
import { makeStyles } from '@material-ui/styles';
import * as api from '../../services/api';
import { SuperAdminErrorMessage } from './SuperAdminErrorMessage';

const useStyles = makeStyles(() => ({
    paper: { 
        minWidth: "600px", 
        minHeight:"220px", 
        padding:"25px 60px" 
    },
    actionButton: {
        padding:"12px 35px", 
        fontSize:"14px", 
        textTransform:"capitalize"
    },
    dialogActions: {
        display: "flex",
        paddingBottom: "30px",
        justifyContent: "center",
        gap: "25px"
    }
}));

export default function ConfirmationDialog({ onConfirm, onClose, ...props }) {
    const classes = useStyles();
    const [openPasswordConfirmation, setOpenPasswordConfirmation] = useState(false);
    const [open, setOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState();
    
    const handleClose = () => {
        onClose()
        setOpenPasswordConfirmation(false)
    };

    const handleAgree = () => {
        if (props.passwordConfirmation) {
            api.post(`admin/userDeleteRequest/${props.user.userName}`, {
                recieverMailAddress: props.authuser.attributes.email
            }).then(res => {
                setOpenPasswordConfirmation(true)
            })
                .catch(error => {
                    console.log(error);
                    if (error.message == "you are not allowed access this resource") {
                        setOpen(true)
                        setAlertTitle("This action can only be performed by Super Admin")
                        handleClose()
                    }
                });

        }
        else {
            onConfirm()
        }
    };

    return (
        <>
            <SuperAdminErrorMessage  alertTitle={alertTitle}  open={open} setOpen={setOpen}/>
            <Dialog
                classes={{ paper: classes.paper }}
                open={props.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle style={{ paddingTop: "30px" }} id="alert-dialog-title"><Typography align="center" variant="h5">{props.title}</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText align="center" id="alert-dialog-description">{props.content}</DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button className={classes.actionButton} variant="contained" color="primary" onClick={handleClose}>
                        {props.cancelButtonText}
                    </Button>
                    <Button className={classes.actionButton} onClick={handleAgree} color="primary" variant="outlined" autoFocus>
                        {props.agreeButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
            {props.passwordConfirmation &&
                <PasswordConfirmationDialog
                    successDialog={props.successDialog}
                    successTitle={props.successTitle}
                    successContent={props.successContent}
                    onConfirm={onConfirm}
                    setSecretCode={props.setSecretCode}
                    secretCode={props.secretCode}
                    open={openPasswordConfirmation} onClose={handleClose}
                    authuser={props.authuser}
                    setOpenSuccessDialog={props.setOpenSuccessDialog}
                    openSuccessDialog={props.openSuccessDialog}
                    setError={props.setError}
                    error={props.error}
                />
            }
        </>
    );
}
