import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  breadcrumb: {
    fontSize: '28px',
    lineHeight: '42px',
    fontWeight: 500,
    color: '#263C67',
    fontFamily: 'Poppins',
  },
  breadcrumbSm: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    color: '#263C67',
    fontFamily: 'Poppins',
  },
}))


export default function CustomBreadCrumb({ breadcrumbs, ...props }) {
  const classes = useStyles();

  return (
    <Box {...props}>
      {
        breadcrumbs.map(({ text, isCurrent }, index) => {
          if (isCurrent) {
            return (
              <Typography
                key={index}
                className={classes.breadcrumb}
                color="textPrimary"
              >
                {text}
              </Typography>
            )
          } else {
            return (
              <Typography
                key={index}
                className={classes.breadcrumbSm}
                color="textPrimary"
              >
                {text} &gt;
              </Typography>
            )
          }
        })
      }
    </Box>

  )
}

CustomBreadCrumb.propTypes = {
  className: PropTypes.array,
};
