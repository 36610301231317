import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Typography } from '@material-ui/core';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  textGreen:{
    color:'green'
  },
  textOrange:{
    color:'orange'
  },
  buttonRenew:{
    textDecoration: 'none'
  }
});

function createData(date, amount, status) {
  return { date, amount, status };
}

const rows = [
  createData('xx/xx/xxxx', 159, 'P'),
  createData('xx/xx/xxxx', 159, 'P'),
  createData('xx/xx/xxxx', 159, 'S'),
  createData('xx/xx/xxxx', 159, 'S')
];

export default function BillingHistoryTable({ renewTransaction, ...props }) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="right">Amount ($)</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.date}>
              <TableCell component="th" scope="row">
                {row.date}
              </TableCell>
              <TableCell align="right">{row.amount}</TableCell>
              <TableCell align="center">
                {row.status === "P" && <Typography className = {classes.textGreen}>paid</Typography>}
                {row.status === "S" && <Typography className = {classes.textOrange}>scheduled</Typography>}
              </TableCell>
              <TableCell align="center">
                <Button>
                  <Typography style={{textTransform: 'none'}}>Renew</Typography>
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
