import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({ // eslint-disable-line no-unused-vars
  breadcrumb: {
    fontSize: '16px',
    fontWeight: 600,
    cursor: 'pointer'
  },
}))

const Settings = () => {
  const classes = useStyles();

  return (
    <div>
      <p>OGMO Settings are Loading...</p>
    </div>
  );
};

export default Settings;
