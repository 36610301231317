import React from 'react';
import {
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
}));

function SubscribersTableHead() {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell>Email</TableCell>
        <TableCell>Name </TableCell>
        <TableCell>joinedDate </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default SubscribersTableHead;
