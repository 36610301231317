import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Box, Grid, IconButton } from '@material-ui/core';
import { PhotoCamera } from '@material-ui/icons';
import defaultPic from '../../images/user.png';
import { Auth } from 'aws-amplify';
import * as api from '../../services/api';
import CustomBreadCrumb from '../../components/Shared/CustomBreadCrumb';
import Badge from '@material-ui/core/Badge';

import InputField  from '../../components/Shared/InputField';
import InputFieldLabel from '../../components/Shared/InputFieldLabel';
import StyledButton from '../../components/Shared/StyledButton';

import PageLoader from './../../components/PageLoader/PageLoader'

import useUserStore from './../../components/User/UserStore';

import { UNKNOWN_USER_FIRST_NAME } from './../../util/constants';
import { checkUsernameIsLimited } from './../../util/';

const useStyles = makeStyles(theme => ({ 
  root: {
    paddingLeft:'20px',
    paddingRight:'20px',
  },
  avatarLarge: {
    width: theme.spacing(19),
    height: theme.spacing(19),
  },
  textfield: {
    width: "100%",
    maxWidth: "376px",
    paddingLeft:'20px',
  },
  hiddenInput: {
    display: 'none'
  },
  button: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(2),
  },
  badge:{
    background: theme.palette.disabled.light,
    '&:hover': {
      background: theme.palette.disabled.main,
    },
  },
}))

const UserProfile = () => {
  const classes = useStyles();
  const [user, setUser] = useState({ userFirstName: UNKNOWN_USER_FIRST_NAME, userEmail: UNKNOWN_USER_FIRST_NAME });

  const [isInProgress, setIsInProgress] = useState(false);
  const [file, setFile] = useState(null);

  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [isGoogleUser, setIsGoogleUser] = useState(false);

  const setProfileAvatar = useUserStore(state => state.setProfileAvatar);
  const loggedInUserDetail = useUserStore(state => state.loggedInUserDetail);
  const setLoggedInUserFirstName = useUserStore(state => state.setLoggedInUserFirstName);

  useEffect(() => {
    setIsInProgress(true)
    if(loggedInUserDetail.userFirstName !== UNKNOWN_USER_FIRST_NAME) {
      getAuthUser();
    }
  }, [loggedInUserDetail])

  async function getAuthUser() {
    try {
      const auth = await Auth.currentAuthenticatedUser();  
      if (auth) {
        if(!checkUsernameIsLimited(auth.username)){
          setIsUpdateDisabled(false);
        } else {
          setIsUpdateDisabled(true);
        }
        if (auth.username.split('_')[0] === 'google') {
          setIsGoogleUser(true);
        } else {
          setIsGoogleUser(false);
        }
        getUser();
      }
    }
    catch (err) {
      console.log('Auth user error: ', err);
      setIsInProgress(false);
    }
  }

  function getUser() {
    setUser(loggedInUserDetail);
    setIsInProgress(false);
  }

  const setUserFirstName = (e) => {
    const val = e.target.value;
    setUser(prevState => {
      return { ...prevState, userFirstName: val }
    });
  }

  const setUserEmail = (e) => {
    const val = e.target.value;
    setUser(prevState => {
      return { ...prevState, userEmail: val }
    });
  }

  const handleImgChange = (e) => {
    if(0 < e.target.files.length) {
      let file = e.target.files[0];
      setFile(file);
      setUser(prevState => {
        return { ...prevState, userLogo: URL.createObjectURL(file) }
      });
    } else {
      setFile(null);
      setUser(prevState => {
        return { ...prevState, userLogo: loggedInUserDetail.userLogo }
      });
    }
  };

  async function handleSubmit() {
    if (file !== null) {
      setIsInProgress(true);
      handleLogoUpload()
    } else if (file === null && user.userFirstName !== loggedInUserDetail.userFirstName) {
      setIsInProgress(true);
      updateUser();
    } else {
      console.log('Nothing has been changed to Update!');
    }
  }

  async function handleLogoUpload() {
      if (loggedInUserDetail.userLogo && loggedInUserDetail.userLogo !== "") {
        await api.post('common/removeAsset',
          {
            userId: loggedInUserDetail.userName,
            url: loggedInUserDetail.userLogo
          })
          .then(_ => {
            uploadAsset();
          })
          .catch(error => {
            console.log('removeAsset Error: ', error.message);
            setIsInProgress(false);
          });
      } else {
        uploadAsset();
      }
  }

  async function uploadAsset() {
    api.post('common/uploadAsset', {
      dataType: "user-data",
      userId: loggedInUserDetail.username,
      designId: "",
      assetType: "profile",
      assetName: file.name
    })
      .then(data => {
        uploadAssetWithUrl(data.data.uploadUrl, data.data.downloadUrl);
      })
      .catch(error => {
        setIsInProgress(false);
        console.log('Faild to uploadAsset: ', error.message);
      });
  }

  function uploadAssetWithUrl(uploadUrl, downloadUrl) {
    fetch(uploadUrl, {
      method: "PUT", body: file, headers: { 'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded' }
    })
      .then(_ => {
        setProfileAvatar(downloadUrl);
        setUser(prevState => {
          return { ...prevState, userLogo: downloadUrl }
        });
        updateUser(downloadUrl);
      })
      .catch((error) => {
        setIsInProgress(false);
        console.log('uploadAssetWithUrl API call err: ', error)
      });
  }

  const updateUser = (downloadUrl) => {
    api.put(`users/${loggedInUserDetail.userName}`, {
        userName : loggedInUserDetail.userName,
        userEmail : user.userEmail,
        userFullName: loggedInUserDetail.userFullName,
        userFirstName: user.userFirstName,
        userLogo: downloadUrl ? downloadUrl : user.userLogo
    })
      .then(_ => {
        setIsInProgress(false);
        setFile(null);
        setLoggedInUserFirstName(user.userFirstName);
      })
      .catch(error => {
        setIsInProgress(false);
        console.log('Faild to updateUser: ', error.message);
      });
  };

  if (isInProgress) {
    return(
      <PageLoader/>
    );
  } else {
    return (
      <Box className={classes.root}>
        <CustomBreadCrumb breadcrumbs={[
          {text: "Settings", isCurrent: false},
          {text: "User Profile", isCurrent: true},
        ]} style={{marginBottom: "30px"}}/>
        <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
          badgeContent={<div><input accept="image/*" className={classes.hiddenInput} id="icon-button-file" type="file" disabled={isGoogleUser} onChange={handleImgChange} />
          <label htmlFor="icon-button-file"><IconButton color="primary" aria-label="upload picture" component="span" className={classes.badge}>
              <PhotoCamera />
            </IconButton></label></div>} >
          <Avatar alt="Remy Sharp" src={user.userLogo === "" ? defaultPic : user.userLogo} className={classes.avatarLarge} />
        </Badge>
        <Grid container>
          <Grid item xs={12} md={4} style={{marginRight:'20px'}}>
            <InputFieldLabel >User Name</InputFieldLabel>
            <InputField className={classes.textfield} id="user-name" value={user.userFirstName} onChange={setUserFirstName} disabled={isGoogleUser || isUpdateDisabled} />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputFieldLabel >Email</InputFieldLabel>
            <InputField className={classes.textfield} id="user-name" value={user.userEmail} onChange={setUserEmail} disabled />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StyledButton className={classes.button} disabled={isGoogleUser} onClick={handleSubmit}>Save</StyledButton>
        </Grid>
      </Box>
  
    );
  }
};

export default UserProfile;
