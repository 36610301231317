import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Typography,
  Box,
  MenuItem,
  IconButton,
  Menu
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Auth } from 'aws-amplify';
import tempImg from '../../../../images/user.png'
import * as api from '../../../../services/api';
import useUserStore from './../../../../components/User/UserStore';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 'fit-content'
  },
  avatar: {
    width: 30,
    height: 30
  },
  Typography: {
    marginLeft: theme.spacing(1)
  },
  button: {
    width: 20,
    marginLeft: 10,
    '&:hover': { backgroundColor: 'white' }
  },
  BlockStatus: {
    height: 'center',
    borderRadius: 4,
    color: 'white',
    fontSize: '16px',
    textJustify: 'center'
  },
  SuperAdminBlockStatus: {
    backgroundColor: '#ff4d4d',
  },
  AdminBlockStatus: {
    backgroundColor: 'orange',
  }
}));

export default function Profile({ className, ...rest }) {
  const loadingText = "Loading";
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const loggedInUserDetail = useUserStore(state => state.loggedInUserDetail);
  const setLoggedInUserDetail = useUserStore(state => state.setLoggedInUserDetail);
  const resetLoggedInUserDetail = useUserStore(state => state.resetLoggedInUserDetail);

  useEffect(() => {
    setLoggedInUserDetail({ ...loggedInUserDetail, userFirstName: loadingText, userEmail: loadingText});
      Auth.currentAuthenticatedUser()
      .then(data => {
        if(0 < data.attributes.name.length) {
          setLoggedInUserDetail({...loggedInUserDetail, userFirstName: data.attributes.name.split(" ")[0], userEmail: data.attributes.email? data.attributes.email : loadingText })
        }
        
        getUser(data.username);
      }) 
  }, []);



  async function getUser(userId) {
    await api.get(`users/${userId}`)
      .then(data => {
        setLoggedInUserDetail(data.data);
      })
      .catch(error => {
        console.log('Get User Error: ', error.message);
        resetLoggedInUserDetail();
        signOut();
      });
  }


  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    Auth.signOut()
      .catch(e => {
        console.error(e)
      });

    handleClose();
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={loggedInUserDetail.userLogo}
        to="/settings/userprofile"
      />
      <Box
        className={classes.Typography}
        display="flex"
        flexDirection="column"
      >
        <Typography
          data-test="header-profile-name"
          variant="subtitle1"
        >{loggedInUserDetail.userFirstName}</Typography>
      </Box>
      <IconButton
        className={classes.button}
        onClick={handleClick}
      >
        <ArrowDropDownIcon
          color="action"
          fontSize="small"
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="long-menu"
        onClose={handleClose}
        open={open}
      >
        <MenuItem onClick={signOut}>Sign Out</MenuItem>
      </Menu>
    </Box>
  );
}
