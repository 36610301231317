import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as api from '../../services/api';
import { Auth } from 'aws-amplify';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import InputField from '../Shared/InputField';
import StyledButton from '../Shared/StyledButton';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({ 
  adornment: {
    paddingLeft: '15px',
    color: '#D6D6D6',
  },
  adornmentEnd: {
    height: '16px',
    width: '16px',
    marginRight: '15px',
    color: '#D6D6D6',
    cursor: 'pointer',
  },
  button: {
    marginLeft: '10px',
  },
  numOfDesignsText: {
    fontFamily: 'Poppins , sans-serif',
    color: '#263C67',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
  },
}))

const SearchBar = ({ setSearchText, designCount, ...props}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");

  const setSearchTermOnEnter = (e) => {
    if (e.key === 'Enter') {
      setSearchText(searchTerm);
    }
  }

  const onSearchClick = () => {
    setSearchText(searchTerm);
  }

  const clearSearchTerm = () => {
    setSearchTerm('');
    setSearchText('');
  }

  return (
    <Box display="flex" alignItems="center">
      <InputField
        value={searchTerm}
        autoFocus
        variant="outlined"
        placeholder="Search by name"
        disableUnderline
        onChange={event => setSearchTerm(event.target.value)}
        onKeyPress={setSearchTermOnEnter}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon className={classes.adornment} />
          </InputAdornment>
        }
        endAdornment={
          searchTerm.length > 0 ? <InputAdornment position="end" onClick={clearSearchTerm}>
            <CloseIcon className={classes.adornmentEnd} />
          </InputAdornment> : null
        }
      />
      <StyledButton
        className={classes.button}
        onClick={onSearchClick}
      >
        search
      </StyledButton>
      <div style={{ marginLeft: '60px' }}>
        <Typography className={classes.numOfDesignsText}>
          {designCount? designCount: 0} Subscriber(s) available
        </Typography>
      </div>
    </Box>
  )
}

SearchBar.propTypes = {
  setSearchText: PropTypes.func.isRequired,
  designCount: PropTypes.number,
};

export default SearchBar;