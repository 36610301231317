import palette from './palette';

export default {
  fontFamily: 'Poppins , sans-serif',
  h1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '35px',
    letterSpacing: '-0.24px',
    lineHeight: '40px'
  },
  h2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '34px',
    letterSpacing: '-0.24px',
    lineHeight: '51px'
  },
  h3: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '32px',
    letterSpacing: '-0.06px',
    lineHeight: '48px'
  },
  h4: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '24px',
    letterSpacing: '-0.06px',
    lineHeight: '36px'
  },
  h5: {
    color: palette.text.primary,
    fontWeight: 600,
    fontSize: '18px',
    letterSpacing: '-0.05px',
    lineHeight: '27px'
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px'
  },
  subtitle1: {
    color: '#263C67',
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '21px',
    fontWeight: '500',
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '21px'
  },
  body1: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '-0.05px',
    lineHeight: '24px'
  },
  body2: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.04px',
    lineHeight: '21px'
  },
  button: {
    color: palette.text.primary,
    fontSize: '12px'
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '11px',
    letterSpacing: '0.33px',
    lineHeight: '13px'
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    textTransform: 'uppercase'
  },
  fontWeightRegular: 500,
  fontWeightMedium: 600,
  fontSizeMedium: {
    fontSize: '16px',
    lineHeight: '24px'
  }
};
