import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import * as api from '../../services/api';
import { Auth } from 'aws-amplify';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import SubscribersTable from '../../components/Subscribers/SubscribersTable';
import InputField from '../../components/Shared/InputField';
import StyledButton from '../../components/Shared/StyledButton';
import CloseIcon from '@material-ui/icons/Close';

import CustomBreadCrumb from '../../components/Shared/CustomBreadCrumb';
import SearchBar from '../../components/Subscribers/SearchBar';

const useStyles = makeStyles(theme => ({ 
  container: {
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  adornment: {
    paddingLeft: '15px',
    color: '#D6D6D6',
  },
  endadornment: {
    height: '16px',
    width: '16px',
    marginRight: '15px',
    color: '#D6D6D6',
    cursor: 'pointer',
  },
  button: {
    marginLeft: '10px',
  },
  tablebox: {
    marginTop: '32px',
  },
  numOfDesignsText: {
    fontFamily: 'Poppins , sans-serif',
    color: '#263C67',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: '500',
  },
}))

const Subscribers = () => {
  const classes = useStyles();
  const [authuser, setAuthuser] = useState(null);
  const [isLoading, setIsLoading] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState('');
  const [tokenHistory, setTokenHistory] = useState([null]);
  const [page, setPage] = useState(0);

  const [subscribers, setSubscribers] = useState({
    TOTAL_SUBSCRIBERS: 0,
    SUBSCRIBER_COUNT: 0,
    SUBSCRIBERS: [],
    PGN_TOKEN: ''
  })

  useEffect(() => {
    getAuthUser();
  }, [])

  useEffect(() => {
    if(authuser !== null) {
      loadSubscribers(page, subscribers.PGN_TOKEN);
    }
  }, [authuser, rowsPerPage, page, searchText])

  const getAuthUser = async() => {
    try {
      const auth = await Auth.currentAuthenticatedUser();
      if (auth) {
        setAuthuser(auth);
      }
    }
    catch (err) {
      console.log('Auth user error: ', err)
    }
  }

  const getSubscriberTableData = (users, token) => {
    const subscribers = users.map(user => {
      user.joinedDate = user.UserCreateDate
      user.id = user.Username
      user.email = user.Email
      user.Attributes.forEach(el => {
        user[el.Name] = el.Value
      })
      return user
    });

    const tableData = {
      TOTAL_SUBSCRIBERS: subscribers.length,
      SUBSCRIBER_COUNT: subscribers.length,
      SUBSCRIBERS: subscribers,
      PGN_TOKEN: token
    }
    return tableData
  }

  const handleChangePage = (newPage) => {
    if (page<newPage){
      const token = subscribers.PGN_TOKEN
      setTokenHistory(prev => [...prev, token])
    }
    if (page>newPage){
      const prev_token = tokenHistory[newPage]
      setSubscribers(prev=>({...prev, PGN_TOKEN: prev_token}))
    }
    setPage(newPage);
  };

  const handleSetRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setSubscribers(prev=>({...prev, PGN_TOKEN: null}))
    setTokenHistory([null])
  };

  const loadSubscribers = (page, token) => {
    setIsLoading(true);

    let nextDesignStartKey = tokenHistory;
    if (page == 0) {
      nextDesignStartKey = '';
    }

    let url = `users/all?limit=${rowsPerPage}&filter=${searchText}`
    
    if (token && token.length>0){
      url = `users/all?limit=${rowsPerPage}&filter=${searchText}&paginationToken=${encodeURIComponent(token)}`
    }
    if (searchText.length>0){
      url = `users/all?limit=${rowsPerPage}&filter=${searchText}`
    }

    api.get(url)
      .then(data => {
        const pagination_token = data.data['paginationToken'] ? data.data['paginationToken'] : null
        if (data.data.Users.length>0) {
          const subscribers = getSubscriberTableData(data.data.Users, pagination_token)
          setSubscribers(subscribers);
        }
        setIsLoading(false);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <Box className={classes.container}>
      <CustomBreadCrumb breadcrumbs={[
        {text: "Subscribers", isCurrent: true},
      ]} style={{marginBottom: "30px"}}/>

      <SearchBar setSearchText={setSearchText} designCount={searchText == '' ? subscribers.TOTAL_SUBSCRIBERS : subscribers.SUBSCRIBER_COUNT}/>

      <Box className={classes.tablebox}>
        <SubscribersTable
          subscribers={subscribers.SUBSCRIBERS}
          showDate
          isLoading={isLoading}
          handleRowsPerPageChange={handleSetRowsPerPage}
          rowsPerPage={rowsPerPage}
          handlePageChange={(_, page) => handleChangePage(page)}
          page={page}
          count={searchText == '' ? subscribers.TOTAL_SUBSCRIBERS : subscribers.SUBSCRIBER_COUNT}
          pageToken={subscribers.PGN_TOKEN}
        />
      </Box>
    </Box>
  );

};

export default Subscribers;
