import React from 'react';
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import DownloadIcon from '@material-ui/icons/GetApp';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import StyledButton from './../Shared/StyledButton';

const useStyles = makeStyles((theme) => ({
  marginRight: {
    marginRight: theme.spacing(2),
  },
  downloadBtn: {
    marginRight: theme.spacing(2),
    backgroundColor: "#152e68",
    "&:hover": {
      backgroundColor: '#1D4C92'
    }
  },
}));

export default function Toolbar({downloadReport,blockUser, removeUser, ...props}) {
  const classes = useStyles();

  return (
    <Grid container item xs={12} spacing={3} justifyContent="flex-end" style={{justifyContent:"flex-end"}}>
      <StyledButton disabled={false} color="primary" onClick={downloadReport}   className={classes.downloadBtn}  startIcon={<DownloadIcon />}>Download Report</StyledButton>
      <StyledButton disabled={false} color="secondary" className={classes.marginRight} onClick={blockUser} startIcon={<WarningRoundedIcon />}>{props.isUserBlocked ? "Unblock " : "Block "} User</StyledButton>
      <StyledButton disabled={false} onClick={removeUser} startIcon={<WarningRoundedIcon />}>Remove User</StyledButton>
    </Grid>
  );
}
