import React from 'react';
import {
  Typography,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { formatDate } from './../../util/';

export default function SubscribersTableRow({ subscriber }) {
  const history = useHistory();

  const handleSubscriberRowClick = event => {
    event.preventDefault();
    const joinedDate = formatDate(subscriber.UserCreateDate);
    history.push({
      pathname: `/updateSubscriber/${subscriber.id}`,
      state: { 'joinedDate': joinedDate }
    })
  };

  return (
    <TableRow hover key={subscriber.id} onClick={handleSubscriberRowClick}>
      <TableCell display="flex">
        <Typography>{subscriber.email}</Typography>
      </TableCell>
      <TableCell display="flex">
        <Typography>{subscriber.name}</Typography>
      </TableCell>
      <TableCell display="flex">
        <Typography>{formatDate(subscriber.UserCreateDate)}</Typography>
      </TableCell>
    </TableRow >
  );
}
