import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { 
  makeStyles,
  Grid,
  Box
} from '@material-ui/core';
import * as api from '../../services/api';
import { Auth } from 'aws-amplify';
import LoaderElement from '../../components/PageLoader/LoaderElement';
import axios from 'axios';
import { DateRangePicker } from "materialui-daterange-picker";
import Toolbar from '../../components/Subscribers/Toolbar';
import UserCard from '../../components/Subscribers/UserCard';
import SubscriptionTabPanel from '../../components/Subscribers/SubscriptionTabPanel';
import PageLoader from '../../components/PageLoader/PageLoader';
import ConfirmationDialog from '../../components/Shared/ConfirmationDialog';
import { SuperAdminErrorMessage } from '../../components/Shared/SuperAdminErrorMessage';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    minWidth: "1200px",
    minHeight: "220px",
    padding: "25px 60px"
  },
  actionButton: {
    padding: "12px 35px",
    fontSize: "14px",
    textTransform: "capitalize"
  },
  dialogActions: {
    display: "flex",
    justifyContent:"flex-end",
    marginRight: "15px",
    gap: "25px"
  },
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const UpdateSubscriber = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [authuser, setAuthuser] = useState(null);
  const [isInProgress, setIsInProgress] = useState(false);
  const [openBlockConfirmationDialog, setOpenBlockConfirmationDialog] = useState(false);
  const [openRemoveConfirmationDialog, setOpenRemoveConfirmationDialog] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const [openPicker, setOpenPicker] = useState(true);
  const [user, setUser] = useState({})
  const [design, setDesign] = useState({})
  const [billing, setBilling] = useState({})
  const [planDef, setPlanDef] = useState({})
  const [secretCode, setSecretCode] = useState()
  const [error, setError] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertTitle, setAlertTitle] = useState();

  let sixMonths = new Date();
  sixMonths.setMonth(sixMonths.getMonth() - 6);
  sixMonths.setHours(0, 0, 0, 0);

  let lastYear = new Date();
  lastYear.setMonth(lastYear.getMonth() - 12);
  lastYear.setHours(0, 0, 0, 0);

  let thisMonth = new Date();
  thisMonth.setDate(1);
  thisMonth.setHours(0, 0, 0, 0);

  let lastMonth = new Date();
  lastMonth.setDate(1);
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  lastMonth.setHours(0, 0, 0, 0);

  let today = new Date();
  let lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
  let lastDayOfThisMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);

  let todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0);

  let lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);
  lastWeek.setHours(0, 0, 0, 0);

  const dataRanges = [
    {
      label: "Today",
      startDate: todayStart,
      endDate: new Date()
    },
    {
      label: "Yesterday",
      startDate: yesterday,
      endDate: yesterday
    },
    {
      label: "Last 7 Days",
      startDate: lastWeek,
      endDate: new Date()
    },
    {
      label: "This Month",
      startDate: thisMonth,
      endDate: lastDayOfThisMonth
    },
    {
      label: "Last Month",
      startDate: lastMonth,
      endDate: lastDayOfLastMonth
    },
    {
      label: "Last Six Months",
      startDate: sixMonths,
      endDate: new Date()
    },
    {
      label: "Last Year",
      startDate: lastYear,
      endDate: new Date()
    },
  ];

  const handleCloseModal = () => {
    setOpenModal(false);
    setDateRange(null)
  };
  
  useEffect(() => {
    getAuthUser();
  }, [])

  useEffect(() => {
    if (authuser !== null) {
      fetchData(props.match.params.subscriberId);
    }
  }, [authuser])

  async function getAuthUser() {
    try {
      const auth = await Auth.currentAuthenticatedUser();
      if (auth) {
        setAuthuser(auth);
      }
    }
    catch (err) {
      console.log('Auth user error: ', err)
    }
  }

  const blockUser = () => {
    const block_user = api
      .post(`admin/blockUser/${user.userName}`, {
        blockUser: !user.blockUser,
      })
      .then(() => {
        setOpenBlockConfirmationDialog(false);
        fetchData(props.match.params.subscriberId);
      })
      .catch((err) => {
        console.log(err)
        if (err.message == "you are not allowed access this resource") {
          setOpenAlert(true);
          setAlertTitle("This action can only be performed by Super Admin")
          setOpenBlockConfirmationDialog(false);
        }
      });
  };

  const removeUser = () => {
    const remove_user = api
      .remove(`admin/removeUser/${user.userName}`, {
        inputSecretCode: secretCode
      })
      .then(() => {
        setError(false)
        setOpenSuccessDialog(true)
        setSecretCode('')
        setOpenRemoveConfirmationDialog(false);
      })
      .catch((err) => {
        setSecretCode('')
        setError(true)
        console.log(err)
      });
  };

  const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    const arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    let CSV = "";

    if (ShowLabel) {
      let row = "";

      for (let index in arrData[0]) {
        row += index + ",";
      }
      row = row.slice(0, -1);
      CSV += row + "\r\n";
    }

    for (let i = 0; i < arrData.length; i++) {
      let row = "";

      for (let index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }
      row.slice(0, row.length - 1);
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    let fileName = "DesignViewCountReport_";
    fileName += ReportTitle.replace(/ /g, "_");
    let uri = "data:text/csv;charset=utf-8," + escape(CSV);
    let link = document.createElement("a");
    link.href = uri;
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadReport = () => {
    let startDate = new Date(dateRange.startDate);
    let endDate = new Date(dateRange.endDate);

    startDate.setUTCDate( startDate.getUTCDate() + 1 );
    endDate.setUTCDate( endDate.getUTCDate() + 1 );
    
    const downloadReport = api
      .post(`designs/getDesignViewCount/${user.userName}`,
        {
          startDate: startDate,
          endDate: endDate
        })
      .then(res => {
        setDateRange(null)
        setIsLoading(false)
        setOpenModal(false)
        if (res.data.reportdata.length !== 0) {
          JSONToCSVConvertor(res.data.reportdata, user.userName, true)
        } else {
          setOpenAlert(true)
          setAlertTitle("No data to retrieve on this date range")
        }
      })
      .catch(err => {
        console.log(err.message)
        setDateRange(null)
        setIsLoading(false)
        setOpenModal(false)
      });
  };

  const fetchData = (userId) => {
    const users = api.get(`users/${userId}`)
    const plans = api.get(`billing/pricePlans?userId=${userId}`)
    const designs = api.get(`users/designsummery/${userId}`)
    setIsInProgress(true)
    Promise.all([users, plans, designs])
      .then(results => {
        setUser(results[0].data)
        setBilling(results[1].data)
        setDesign(results[2].data)
        return api.get(`billing/pricePlanDefs/${results[1].data.pricePlanDefId}`)
      })
      .then(response => {
        setPlanDef(response.data)
        setIsInProgress(false)
      })
      .catch(err => {
        console.log("Fetch data error: ", err);
      });
  }

  return (
    <div className={classes.root}>
      <SuperAdminErrorMessage open={openAlert} alertTitle={alertTitle} setOpen={setOpenAlert}/>
      {isInProgress ? 
        <PageLoader /> :
        <Grid container spacing={3}>
          <Toolbar downloadReport={()=>setOpenModal(true)} isUserBlocked={user.blockUser} blockUser={()=>setOpenBlockConfirmationDialog(true)} removeUser={()=>setOpenRemoveConfirmationDialog(true)} />
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} md={6}>
              <UserCard
                userEmail={user.userEmail}
                userName={user.userFullName}
                userLogo={user.userLogo}
                isUserBlocked={user.blockUser}
                joinedDate={props.history.location.state?.joinedDate} />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} md={8} lg={6}>
              <SubscriptionTabPanel designs={design} billing={billing} planDef={planDef} />
            </Grid>
          </Grid>
        </Grid>
      }
      <ConfirmationDialog
        key={`remove_${props.match.params.subscriberId}`}
        title={`Are you sure you want to Remove User ${user.userFullName} ?`}
        content="Once you remove, the user will loose all his/her data from OGMO and the action cannot be undone! Please confirm your operation"
        cancelButtonText="Cancel"
        agreeButtonText="Remove User"
        open={openRemoveConfirmationDialog}
        passwordConfirmation={true}
        successDialog={true}
        successTitle="User successfully removed!"
        successContent="An email has been sent to the removed user. The effect will take place after xx days."
        onConfirm={removeUser}
        authuser={authuser}
        onClose={() => setOpenRemoveConfirmationDialog(false)}
        user={user} 
        secretCode={secretCode}
        setSecretCode={setSecretCode}
        setOpenSuccessDialog={setOpenSuccessDialog}
        openSuccessDialog={openSuccessDialog}
        setError={setError}
        error={error}/>
      <ConfirmationDialog
        key={`block_${props.match.params.subscriberId}`}
        title={`Are you sure you want to ${user.blockUser ? "Unblock " : "Block "} User ${user.userFullName} ?`}
        content={`Once you ${user.blockUser ? "Unblock " : "Block "}, the user will ${user.blockUser ? "be " : "not "} able to display their 3D designs`}
        cancelButtonText="Cancel"
        agreeButtonText={`${user.blockUser ? "Unblock " : "Block "} User`}
        open={openBlockConfirmationDialog}
        onConfirm={blockUser}
        onClose={() => setOpenBlockConfirmationDialog(false)} />
      <Dialog
        maxWidth="md"
        classes={{ paper: classes.paper }}
        open={openModal}
        onClose={() => {
          setOpenModal(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle style={{ paddingTop: "10px" }} id="alert-dialog-title"><Typography align="center" variant="h3">Pick your Date Range</Typography></DialogTitle>
        <DialogContent>
          <DateRangePicker
            definedRanges={dataRanges}
            open={openPicker}
            toggle={toggle}
            onChange={(range) => setDateRange(range)}
          />
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button className={classes.actionButton} variant="contained" color="primary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button disabled={dateRange == null && true} className={classes.actionButton} onClick={() => downloadReport()} color="primary" variant="outlined" autoFocus>
            Download Report
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default UpdateSubscriber;
