import React from "react";
import { Box } from "@material-ui/core";
import LoaderElement from './LoaderElement';

const PageLoader = () => {
  return (
    <Box
    style={{ 
      position: 'fixed',
      top: "50%",
      left: "50%",
      transform: `translate(50%, -50%)`
    }}
    >
      <LoaderElement/>
    </Box>
  );
}

export default PageLoader;