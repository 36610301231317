import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const SuperAdminErrorMessage = ({open,setOpen,alertTitle}) => {
    const handleAlertClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
		setOpen(false);
	};

    return (
        <>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={6000} open={open} onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity="error">
                    {alertTitle}
                </Alert>
            </Snackbar>
        </>
    )
}
